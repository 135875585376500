import React, { Component } from 'react';
import axios from 'axios';

class EnterPassword extends Component {

    state = {
        password: '',
        errorMsg: null
    }

    componentDidMount() {
        this.passwordInput.focus();
    }

    passwordInputChangeHandler = (event) => {
        this.setState({ password: event.target.value });
    }

    passwordSubmitHandler = (event) => {
        event.preventDefault();
        
        this.setState({errorMsg: null});
        const data = {
            userId: this.props.user.id,
            password: this.state.password
        };
        axios.post('https://dc.spoltzsolutions.com/api/login.php', data, {withCredentials: true})
            .then(response => {
                if (response.data.pass) {
                    this.props.successHandler(event);
                } else {
                    this.setState({errorMsg: 'Incorrect password'});
                }
            });
    }

    render () {
        const errorMsg = (this.state.errorMsg == null) ?
            null : <div className="error">{this.state.errorMsg}</div>

        return (
          <div>
              <div className="quarter-header">
                <h1>Login</h1>
              </div>
              <div className="caption">
                  Welcome, {this.props.user.first_name}
              </div>
              {errorMsg}
              <form onSubmit={this.passwordSubmitHandler}>
                <input type="password"
                    name="password" 
                    className="password-entry"
                    placeholder="Password"
                    ref={(input) => { this.passwordInput = input; }}
                    onChange={this.passwordInputChangeHandler}
                    value={this.state.firstPassword} />
                <button type="button" className="form-button" onClick={this.props.cancelHandler}>Cancel</button>
                <button type="submit" className="form-button">Submit</button>
              </form>
          </div>
        );
    }
}

export default EnterPassword;
