import React, { Component } from 'react';
import axios from 'axios';

class Login extends Component {

    state = {
        users: null
    }

    componentDidMount() {
        axios.get('https://dc.spoltzsolutions.com/api/users.php?ver=' + Date.now())
            .then(response => {
                this.setState({ users: response.data});
            });
    }

    render () {
        const userList = (this.state.users === null) ?
            <div>Loading users...</div> :
            this.state.users.map( user => {
                const clickAction = user.has_password > 0 ?
                    () => this.props.enterExistingPasscode(user) : 
                    () => this.props.createPasscode(user);
                return <div className="button" key={user.id} onClick={clickAction}>{user.first_name}</div>
            });

        return (
          <div>
              <img className="logo" alt="Uncle Mike and Adam's DC Scavenger Hunt" src="./images/logo.png" />
              {userList}
          </div>
        );
    }
}

export default Login;
