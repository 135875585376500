import React, { Component } from 'react';
import axios from 'axios';

import './App.css';

import Login from './components/Login/Login';
import CreatePassword from './components/CreatePassword/CreatePassword';
import EnterPassword from './components/EnterPassword/EnterPassword';
import Main from './components/Main/Main';
import Admin from './components/Admin/Admin';
import ReadOnly from './components/ReadOnly/ReadOnly';

class App extends Component {
    state = {
        screen: null
    }

    componentDidMount() {
        axios.get('https://dc.spoltzsolutions.com/api/session_check.php?ver=' + Date.now(), {withCredentials: true})
            .then(response => {
                if (response.data.logged_in) {
                    this.setState({user: response.data.user});
                    this.showMain();
                } else {
                    this.setState({screen: 'login'});
                }
            });
    }

    existingPasswordHandler = (user) => {
        this.setState({
            screen: 'enterPassword',
            user: user
        });
    }

    createPasswordHandler = (user) => {
        this.setState({
            screen: 'createPassword',
            user: user
        });
    }

    backToLogin = (event) => {
        event.preventDefault();

        axios.post('https://dc.spoltzsolutions.com/api/logout.php', {}, {withCredentials: true});

        this.setState({
            screen: 'login',
            user: null
        });
    }

    showMain = (event) => {
        if (event) {
            event.preventDefault();
        }
        if (this.state.user.is_admin) {
            this.setState({screen: 'admin'});
        } else if (this.state.user.first_name === 'Visitor') {
            this.setState({screen: 'readonly'});
        } else {
            this.setState({screen: 'main'});
        }
    }

    render () {
        let page = null;
        switch(this.state.screen) {
            case "login":
                page = <Login enterExistingPasscode={(user) => this.existingPasswordHandler(user)}
                            createPasscode={(user) => this.createPasswordHandler(user)} />
                break;
            case "createPassword":
                page = <CreatePassword user={this.state.user} 
                            cancelHandler={this.backToLogin} 
                            successHandler={this.showMain} />
                break;
            case "enterPassword":
                page = <EnterPassword user={this.state.user} 
                            cancelHandler={this.backToLogin} 
                            successHandler={this.showMain} />
                break;
            case "main":
                page = <Main user={this.state.user} logoutHandler={this.backToLogin} />
                break;
            case "admin":
                page = <Admin adminUser={this.state.user} logoutHandler={this.backToLogin} />
                break;
            case "readonly":
                page = <ReadOnly logoutHandler={this.backToLogin} />
                break;
            default:
                page = <div>
                            <div className="one-third-header">
                                <h1>Mike and Adam's DC Photo Scavenger Hunt</h1>
                            </div>
                            Loading...
                        </div>
                break;
        }
        return (
          <div className="App">
            {page}
          </div>
        );
    }
}

export default App;
