import React, { Component } from 'react';
import Modal from '../UI/Modal/Modal';
import Aux from '../UI/Aux/Aux';

class BrowseAll extends Component {

    state = {
        selectedSubmission: null
    }

    constructor() {
        super();
        this.photoRoot = 'https://dc.spoltzsolutions.com/uploads/';
    }


    selectPhoto = (submission) => {
        this.setState({selectedSubmission: submission});
    }

    deselectPhoto = () => {
        this.setState({selectedSubmission: null});
    }

    render () {
        const showModal = this.state.selectedSubmission != null;
        const modalContent = showModal ? 
            <img className="browse-photo" 
                            alt="Submitted" 
                            src={this.photoRoot + this.state.selectedSubmission.photo_filename} /> :
            <span>Loading...</span>;

        const itemList = this.props.huntItems.map( item => {
            let submissions = (!item.submissions) ?
                'No submissions yet!' :
                item.submissions.map( submission => {
                    let approvalStatus = <div className='browse-status-inreview'>In Review</div>
                    if (submission.approval_status === 'approved') {
                        approvalStatus = <div className='browse-status-approved'>Approved</div>
                    } else if (submission.approval_status === 'rejected') {
                        approvalStatus = <div className='browse-status-rejected'>Rejected</div>
                    }

                    return <div key={submission.user_id + '_' + item.item_id} className="browse-submission">
                        <div className="browse-name">{submission.first_name}:</div>
                        <img className="browse-photo" 
                            alt="Submitted" 
                            src={this.photoRoot + submission.photo_filename}
                            onClick={() => this.selectPhoto(submission)} />
                        {approvalStatus}
                    </div>
                });

            return <div key={item.item_id} className="browse-row">
                        <div className="browse-title">{item.item_title}</div>
                        <div className="browse-submissions">{submissions}</div>
                    </div>
            });

        return  <Aux>
                    <Modal show={showModal} fullHeight={false} modalClosed={this.deselectPhoto}>
                        {modalContent}
                    </Modal>
                    {itemList}
                </Aux>
    }
}

export default BrowseAll;
