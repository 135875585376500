import React, { Component } from 'react';
import axios from 'axios';

class PhotoUpload extends Component {

    state = {
        uploadedItem: null
    }

    constructor(){
        super();
        this.maxWidth = 1000;
        this.maxHeight = 1000;
        this.photoRoot = 'https://dc.spoltzsolutions.com/uploads/';
        this.retrieveBaseUrl = 'https://dc.spoltzsolutions.com/api/retrieve_upload.php?ver=' + Date.now();
     }

    onChange = (event) => {
        event.preventDefault();

        const photoFile = event.target.files[0];

        if (photoFile) {
            const reader = new FileReader();
            reader.onloadend = () => this.onPhotoRead(reader, photoFile.type, photoFile.name);
            reader.onerror = function () {
                this.setState({errorMsg: 'Could not read photo'});
            }
            reader.readAsDataURL(photoFile);
        }
    }

    onPhotoRead = (reader, fileType, filename) => {
        this.setState({errorMsg: null});

        const photoAsDataUrl = reader.result;
        const image = new Image();
        image.onload = () => this.onImageLoaded(image, photoAsDataUrl, fileType, filename);
        image.src = photoAsDataUrl;
    }

    onImageLoaded = (image, imageData, fileType, filename) => {
        const uploadFullRes = () => this.uploadFullResPhoto(this.dataUrlToBlob(imageData), filename);

        const width = image.width;
		const height = image.height;
		const shouldResize = (width > this.maxWidth) || (height > this.maxHeight);

		if (!shouldResize) {
            this.uploadPhoto(this.dataUrlToBlob(imageData), filename, uploadFullRes);
			return;
		}

        const newWidth = (width > height) ? this.maxWidth : width * (this.maxHeight / height);
        const newHeight = (width > height) ? height * (this.maxWidth / width) : this.maxHeight;

		const canvas = document.createElement('canvas');
		canvas.width = newWidth;
		canvas.height = newHeight;

		const context = canvas.getContext('2d');
		context.drawImage(image, 0, 0, newWidth, newHeight);

        const resizedImageData = canvas.toDataURL(fileType);
        this.uploadPhoto(this.dataUrlToBlob(resizedImageData), filename, uploadFullRes);
    }

    dataUrlToBlob = (dataURL) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURL.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURL.split(',')[1]);
        else
            byteString = unescape(dataURL.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
    
        return new Blob([ia], {type:mimeString});
    }

    uploadPhoto = (photoData, filename, fullResUpload) => {
        const formData = new FormData();
        formData.append('photoData', photoData);
        formData.append('filename', filename);
        formData.append('userId', this.props.user.id);
        formData.append('itemId', this.props.item.item_id);
        formData.append('fullRes', false);

        axios.post('https://dc.spoltzsolutions.com/api/upload_photo.php', formData, {
            withCredentials: true,
            headers: { 'content-type': 'multipart/form-data' }
        }).then(response => {
            this.setState({
                uploadedItem: response.data.item
            });
            this.props.refreshHandler();
            fullResUpload();
        });
    }

    uploadFullResPhoto = (photoData, filename) => {
        const formData = new FormData();
        formData.append('photoData', photoData);
        formData.append('filename', filename);
        formData.append('userId', this.props.user.id);
        formData.append('itemId', this.props.item.item_id);
        formData.append('fullRes', true);

        axios.post('https://dc.spoltzsolutions.com/api/upload_photo.php', formData, {
            withCredentials: true,
            headers: { 'content-type': 'multipart/form-data' }
        }).then(response => {
            console.log(response.data);
        });
    }

    render () {
        let photo = null;
        let buttonText = "Upload Photo";
        if (this.state.uploadedItem != null) {
            photo = <img className="uploaded-photo" alt="Uploaded" src={this.photoRoot + this.state.uploadedItem.photo_filename} />;
            buttonText = "Upload New Photo";
        } else if (this.props.item.photo_filename != null) {
            photo = <img className="uploaded-photo" alt="Uploaded" src={this.photoRoot + this.props.item.photo_filename} />;
            buttonText = "Upload New Photo";
        }

        return (
            <div>
                <div className="caption">
                    <a href="./#" onClick={this.props.backHandler}>Back to List</a>
                </div>

                <div className="item-title">{this.props.item.item_title}</div>

                <div className="fileContainer">
                    <div className="button">{buttonText}</div>
                    <input type="file" accept="image/*" onChange={ this.onChange } />
                </div>
                <div>
                    {photo}
                </div>
            </div>
        );
    }
}

export default PhotoUpload;
