import React, { Component } from 'react';
import axios from 'axios';

import KidTotals from '../Admin/KidTotals';
import BrowseAll from '../Admin/BrowseAll';

class ReadOnly extends Component {

    state = {
        huntItems: null
    }

    componentDidMount() {
        this.refreshItems();
    }

    refreshItems = () => {
        axios.get('https://dc.spoltzsolutions.com/api/hunt_items.php?ver=' + Date.now(), {withCredentials: true})
            .then(response => {
                this.setState({ huntItems: response.data });
            });
    }

    render () {
        let content = <div>Loading items...</div>;
        if (this.state.huntItems !== null) {
            content =   <div>
                            <KidTotals huntItems={this.state.huntItems} />
                            <BrowseAll huntItems={this.state.huntItems} />
                        </div>
        }

        return (
          <div>
              Welcome, Visitor!
              (<a href="./#" onClick={this.props.logoutHandler}>Back to Login</a>)
              <img className="logo" alt="Logo" src="./images/logo.png" />
              {content}
          </div>
        );
    }
}

export default ReadOnly;
