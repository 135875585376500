import React, { Component } from 'react';
import axios from 'axios';

import PhotoUpload from "../PhotoUpload/PhotoUpload";

class Main extends Component {

    state = {
        huntItems: null,
        selectedItem: null
    }

    constructor(){
        super();
        this.retrieveBaseUrl = 'https://dc.spoltzsolutions.com/api/hunt_items.php';
     }

    componentDidMount() {
        this.refreshItems();
    }

    refreshItems = () => {
        const url = this.retrieveBaseUrl + "?ver=" + Date.now() + "&userId=" + this.props.user.id
        axios.get(url, {withCredentials: true})
            .then(response => {
                this.setState({ huntItems: response.data});
            });
    }

    selectItem = (item) => {
        this.setState({selectedItem: item});
    }

    deselectItem = () => {
        this.setState({selectedItem: null});
    }

    render () {
        let content = null;

        if (this.state.selectedItem == null) {
            content = <div>Loading items...</div>;

            if (this.state.huntItems) {
                let todoCt = 0;
                let inReviewCt = 0;
                let approvedCt = 0;
                let rejectedCt = 0;
                const itemList = this.state.huntItems.map( item => {
                    let classNames = ['list-item'];
                    if (item.approval_status === 'uploaded') {
                        classNames.push('uploaded-state');
                        inReviewCt++;
                    } else if (item.approval_status === 'approved') {
                        classNames.push('approved-state');
                        approvedCt++;
                    } else if (item.approval_status === 'rejected') {
                        classNames.push('rejected-state');
                        rejectedCt++;
                    } else {
                        todoCt++
                    }
                    return <div key={item.item_id} 
                                className={classNames.join(" ")} 
                                onClick={() => this.selectItem(item)}>{item.item_title}</div>
                });

                content = <div>
                    <table className="summary-table">
                        <thead>
                            <tr>
                                <th>To-Do</th>
                                <th>In Review</th>
                                <th>Approved</th>
                                <th>Rejected</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{todoCt}</td>
                                <td>{inReviewCt}</td>
                                <td>{approvedCt}</td>
                                <td>{rejectedCt}</td>
                            </tr>
                        </tbody>
                    </table>
                    {itemList}
                </div>
            }
                
        } else {
            content = <PhotoUpload user={this.props.user} 
                            item={this.state.selectedItem}
                            refreshHandler={this.refreshItems}
                            backHandler={this.deselectItem} />
        }

        return (
          <div>
              Welcome, {this.props.user.first_name}
              (<a href="./#" onClick={this.props.logoutHandler}>Logout</a>)
              <img className="logo" alt="Uncle Mike and Adam's DC Scavenger Hunt" src="./images/logo.png" />
              {content}
          </div>
        );
    }
}

export default Main;
