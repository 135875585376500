import React, { Component } from 'react';
import axios from 'axios';

class Review extends Component {

    constructor() {
        super();
        this.photoRoot = 'https://dc.spoltzsolutions.com/uploads/';
        this.retrieveBaseUrl = 'https://dc.spoltzsolutions.com/api/retrieve_upload.php?ver=' + Date.now();
    }

    approvalHandler = (userId) => {
        this.updateApprovalStatus(userId, 'approved');
    }
    unapprovalHandler = (userId) => {
        this.updateApprovalStatus(userId, 'uploaded');
    }
    rejectionHandler = (userId) => {
        this.updateApprovalStatus(userId, 'rejected');
    }

    updateApprovalStatus = (userId, newStatus) => {
        const data = {
            userId: userId,
            itemId: this.props.item.item_id,
            newStatus: newStatus
        };
        axios.post('https://dc.spoltzsolutions.com/api/review_submission.php', data)
            .then(response => {
                this.props.updateHandler();
            });
    }

    render () {
        let content = 'No submissions yet!';
        if (this.props.item.submissions) {
            content = this.props.item.submissions.map( submission => {
                const approvedClass = (submission.approval_status === 'approved') ? 'review-button review-approved' : 'review-button';
                const notApprovedClass = (submission.approval_status === 'uploaded') ? 'review-button review-notapproved' : 'review-button';
                const rejectedClass = (submission.approval_status === 'rejected') ? 'review-button review-rejected' : 'review-button';

                return <div key={submission.user_id}>
                    <div className="review-name">{submission.first_name}:</div>
                    <img className="review-photo" alt="To Review" src={this.photoRoot + submission.photo_filename} />
                    <div className="review-container">
                        <div className={approvedClass} 
                                onClick={() => this.approvalHandler(submission.user_id)}>Approved</div>
                        <div className={notApprovedClass}
                                onClick={() => this.unapprovalHandler(submission.user_id)}>Not Approved</div>
                        <div className={rejectedClass}
                                onClick={() => this.rejectionHandler(submission.user_id)}>Rejected</div>
                    </div>
                </div>
            });
        }

        return (
            <div>
                <div className="caption">
                    <a href="./#" onClick={this.props.backHandler}>Back to List</a>
                </div>

                <div className="item-title">{this.props.item.item_title}</div>

                <div>
                    {content}
                </div>
            </div>
        );
    }
}

export default Review;
