import React, { Component } from 'react';
import axios from 'axios';

class CreatePassword extends Component {

    state = {
        firstPassword: '',
        confirmPassword: '',
        errorMsg: null
    }

    componentDidMount() {
        this.passwordInput.focus();
    }

    passwordInputChangeHandler = (event) => {
        this.setState({ firstPassword: event.target.value });
    }

    confirmPasswordInputChangeHandler = (event) => {
        this.setState({ confirmPassword: event.target.value });
    }

    passwordSubmitHandler = (event) => {
        event.preventDefault();
        if (this.state.firstPassword.length < 5) {
            this.setState({errorMsg: "Password must be at least 5 characters long."});
        } else if (this.state.firstPassword !== this.state.confirmPassword) {
            this.setState({errorMsg: "Passwords don't match."});
        } else {
            this.setState({errorMsg: null});
            const data = {
                userId: this.props.user.id,
                password: this.state.firstPassword
            };
            axios.post('https://dc.spoltzsolutions.com/api/set_password.php', data)
                .then(response => {
                    if (response.data.success) {
                        this.props.successHandler(event);
                    } else {
                        this.setState({errorMsg: 'Server error: could not set password.'});
                    }
                });
        }
    }

    render () {
        const errorMsg = (this.state.errorMsg == null) ?
            null : <div className="error">{this.state.errorMsg}</div>

        return (
          <div>
              <div className="quarter-header">
                <h1>Create Password</h1>
              </div>
              <div className="caption">
                  Welcome, {this.props.user.first_name}
              </div>
              {errorMsg}
              <form onSubmit={this.passwordSubmitHandler}>
                <input type="password"
                    name="password" 
                    className="password-entry"
                    placeholder="Password"
                    ref={(input) => { this.passwordInput = input; }}
                    onChange={this.passwordInputChangeHandler}
                    value={this.state.firstPassword} />
                <input type="password" 
                    name="confirmPassword" 
                    className="password-entry"
                    placeholder="Confirm Password"
                    ref={(input) => { this.confirmPasswordInput = input; }}
                    onChange={this.confirmPasswordInputChangeHandler}
                    value={this.state.confirmPassword} />
                <button type="button" className="form-button" onClick={this.props.cancelHandler}>Cancel</button>
                <button type="submit" className="form-button">Submit</button>
              </form>
          </div>
        );
    }
}

export default CreatePassword;
