import React, { Component } from 'react';

class KidTotals extends Component {

    render () {
        const totalItems = this.props.huntItems.length;
        const kidTotals = {};
        this.props.huntItems.forEach( item => {
            if (item.submissions) {
                item.submissions.forEach( submission => {
                    const kidName = submission.first_name;
                    const status = submission.approval_status;
                    if (kidName in kidTotals) {
                        const kidEntry = kidTotals[kidName];
                        kidEntry[status] = (status in kidEntry) ? kidEntry[status] + 1 : 1;
                        kidEntry['total'] = kidEntry['total'] + 1;
                    } else {
                        kidTotals[kidName] = {};
                        kidTotals[kidName][status] = 1;
                        kidTotals[kidName]['total'] = 1;
                    }
                });
            }
        });

        const kidRows = Object.keys(kidTotals).map( name => {
            const sums = kidTotals[name];
            const todo = totalItems - sums['total'];
            return <tr key={name}>
                        <td>{name}</td>
                        <td>{todo}</td>
                        <td>{sums['uploaded']}</td>
                        <td>{sums['approved']}</td>
                        <td>{sums['rejected']}</td>
                    </tr>;
            });

        return <table className="summary-table">
                <thead>
                    <tr>
                        <th>Kid</th>
                        <th>To-Do</th>
                        <th>In Review</th>
                        <th>Approved</th>
                        <th>Rejected</th>
                    </tr>
                </thead>
                <tbody>
                    {kidRows}
                </tbody>
            </table>
    }
}

export default KidTotals;
