import React, { Component } from 'react';
import axios from 'axios';

import Review from '../Review/Review';
import KidTotals from './KidTotals';
import BrowseAll from './BrowseAll';
import Modal from '../UI/Modal/Modal';

class Admin extends Component {

    state = {
        huntItems: null,
        selectedItem: null,
        mode: 'review'
    }

    componentDidMount() {
        this.refreshItems();
    }

    refreshItems = () => {
        axios.get('https://dc.spoltzsolutions.com/api/hunt_items.php?ver=' + Date.now(), {withCredentials: true})
            .then(response => {
                const items = response.data;
                this.setState({ huntItems: items });

                if (this.state.selectedItem != null) {
                    let item;
                    for (item of items) {
                        if (item.item_id === this.state.selectedItem.item_id) {
                            this.setState({ selectedItem: item });
                        }
                    }
                }
            });
    }

    selectItem = (item) => {
        this.setState({selectedItem: item});
    }

    deselectItem = (event) => {
        event.preventDefault();
        this.setState({selectedItem: null});
    }

    toBrowseMode = (event) => {
        event.preventDefault();
        this.setState({mode: 'browse'});
    }

    toReviewMode = (event) => {
        event.preventDefault();
        this.setState({mode: 'review'});
    }

    render () {
        let content = <div>Loading items...</div>;

        if (this.state.huntItems !== null) {
            if (this.state.mode === 'review') {
                const showModal = this.state.selectedItem !== null;
                const modalContent = showModal ? 
                    <Review item={this.state.selectedItem} 
                        adminUser={this.props.adminUser}
                        updateHandler={this.refreshItems}
                        backHandler={this.deselectItem} /> :
                    <span>Loading...</span>;

                const itemList = this.state.huntItems.map( item => {
                    const pendingCt = (item.submissions) ?
                        item.submissions.filter(sub => sub.approval_status === 'uploaded').length :
                        0;
                    const classNames = (pendingCt > 0) ? ['list-item', 'uploaded-state'] : ['list-item'];
                    return <div key={item.item_id} 
                                className={classNames.join(' ')}
                                onClick={() => this.selectItem(item)}>{item.item_title} ({pendingCt} pending)</div>
                });

                content = <div>
                    <a href="/" onClick={this.toBrowseMode}>Switch to Browse Mode</a>
                    <KidTotals huntItems={this.state.huntItems} />
                    {itemList}
                    <Modal show={showModal} fullHeight={true} modalClosed={this.deselectItem}>
                        {modalContent}
                    </Modal>
                </div>
            } else if (this.state.mode === 'browse') {
                content = <div>
                    <a href="/" onClick={this.toReviewMode}>Switch to Review Mode</a>
                    <KidTotals huntItems={this.state.huntItems} />
                    <BrowseAll huntItems={this.state.huntItems} />
                </div>
            }

        }

        return (
          <div>
              Welcome, Admin {this.props.adminUser.first_name}
              (<a href="./#" onClick={this.props.logoutHandler}>Logout</a>)
              <img className="logo" alt="Logo" src="./images/logo.png" />
              {content}
          </div>
        );
    }
}

export default Admin;
