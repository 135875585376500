import React, { Component } from 'react';

import Aux from '../Aux/Aux';
import Backdrop from '../Backdrop/Backdrop';

class Modal extends Component {

    render () {
        return (
            <Aux>
                <Backdrop show={this.props.show} clicked={this.props.modalClosed} />
                <div
                    className='Modal'
                    style={{
                        height: this.props.fullHeight ? '100%' : '',
                        transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
                        opacity: this.props.show ? '1' : '0'
                    }}>
                    {this.props.children}
                </div>
            </Aux>
        )
    }
}

export default Modal;